import * as universal_hooks from '../../../src/hooks.ts';

export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43'),
	() => import('./nodes/44'),
	() => import('./nodes/45'),
	() => import('./nodes/46'),
	() => import('./nodes/47'),
	() => import('./nodes/48'),
	() => import('./nodes/49'),
	() => import('./nodes/50'),
	() => import('./nodes/51'),
	() => import('./nodes/52'),
	() => import('./nodes/53'),
	() => import('./nodes/54'),
	() => import('./nodes/55'),
	() => import('./nodes/56'),
	() => import('./nodes/57'),
	() => import('./nodes/58'),
	() => import('./nodes/59'),
	() => import('./nodes/60'),
	() => import('./nodes/61'),
	() => import('./nodes/62'),
	() => import('./nodes/63'),
	() => import('./nodes/64'),
	() => import('./nodes/65'),
	() => import('./nodes/66')
];

export const server_loads = [0,4,5,6,8,9];

export const dictionary = {
		"/(marketing)/(landing)": [~19,[3]],
		"/admin/(overview)": [~23,[4]],
		"/admin/documents": [~24,[4]],
		"/admin/documents/[id=uuid]": [~25,[4]],
		"/admin/documents/[id=uuid]/edit": [~26,[4]],
		"/admin/operators": [~27,[4]],
		"/admin/payouts": [~28,[4]],
		"/admin/payouts/[id=uuid]": [~29,[4]],
		"/admin/rates": [~30,[4]],
		"/admin/tickets": [~31,[4]],
		"/admin/tickets/[id=uuid]": [~32,[4]],
		"/admin/transactions": [~33,[4]],
		"/admin/transactions/[id=uuid]": [~34,[4]],
		"/admin/users": [~35,[4]],
		"/admin/users/[id=uuid]": [~36,[4]],
		"/(auth)/challenge/turnstile": [~11,[2]],
		"/dashboard/(overview)": [~37,[5]],
		"/dashboard/contacts": [~38,[5]],
		"/dashboard/invites": [~39,[5]],
		"/dashboard/notifications": [40,[5]],
		"/dashboard/payouts": [~41,[5]],
		"/dashboard/payouts/withdraw/(amount)": [~43,[5,6]],
		"/dashboard/payouts/withdraw/destination": [~44,[5,6]],
		"/dashboard/payouts/withdraw/finish": [~45,[5,6]],
		"/dashboard/payouts/withdraw/review": [~46,[5,6]],
		"/dashboard/payouts/[id=uuid]": [~42,[5]],
		"/dashboard/settings/(overview)": [47,[5,7]],
		"/dashboard/settings/bank-accounts": [~48,[5,7]],
		"/dashboard/settings/change-password": [~49,[5,7]],
		"/dashboard/settings/documents": [~50,[5,7]],
		"/dashboard/settings/profile": [~51,[5,7]],
		"/dashboard/tickets": [~52,[5]],
		"/dashboard/tickets/[id=uuid]": [~53,[5]],
		"/dashboard/transactions": [~54,[5]],
		"/dashboard/transactions/[id=uuid]": [~55,[5]],
		"/dashboard/transfer/(amount)": [~56,[5,8]],
		"/dashboard/transfer/batch/(recipients)": [~57,[5,9]],
		"/dashboard/transfer/batch/pay": [~58,[5,9]],
		"/dashboard/transfer/batch/review": [~59,[5,9]],
		"/dashboard/transfer/batch/transparency": [~60,[5,9]],
		"/dashboard/transfer/pay": [~61,[5,8]],
		"/dashboard/transfer/recipient": [~62,[5,8]],
		"/dashboard/transfer/review": [~63,[5,8]],
		"/dashboard/transfer/transparency": [~64,[5,8]],
		"/dashboard/verification/(info)": [~65,[10]],
		"/dashboard/verification/document": [~66,[10]],
		"/(auth)/login": [~12,[2]],
		"/(auth)/onboarding": [~13,[2]],
		"/(auth)/password/forgot": [~14,[2]],
		"/(auth)/password/reset": [~15,[2]],
		"/(marketing)/(legal)/privacy": [20,[3]],
		"/(auth)/signup": [~16,[2]],
		"/(auth)/signup/email": [~17,[2]],
		"/(auth)/signup/phone": [~18,[2]],
		"/(marketing)/(legal)/terms": [21,[3]],
		"/[id=username]": [~22]
	};

export const hooks = {
	handleError: (({ error }) => { console.error(error) }),

	reroute: universal_hooks.reroute || (() => {})
};

export { default as root } from '../root.svelte';