import { i18n } from "$lib/lang/i18n";
import type { Reroute } from "@sveltejs/kit";

const translated: Record<string, string> = {
  "/home": "/"
};

export const reroute: Reroute = (event) => {
  if (event.url.pathname in translated) {
    return translated[event.url.pathname];
  }

  return i18n.reroute()(event);
};
